<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showOverlay === 'listEditDashboard'" class="w-full h-auto md:h-full fixed top-0 md:flex md:flex-row md:justify-center md:items-center md:inset-0 z-30 font-arial text-new-black pointer-events-none">

      <!-- Components -->
      <queryListLernspots />
      <queryListMc />
      <queryListPfadaufgaben />
      <!-- Components -->

      <div class="relative w-full md:w-5/12 md:min-w-100 h-screen min-h-screen md:min-h-0 md:h-auto md:max-h-80  md:overflow-y-auto md:bg-new-yellow md:rounded-t-3xl md:rounded-3xl md:p-5 pointer-events-auto scrollbar flex flex-col">

        <!-- Content -->
        <div class="w-full h-1/6" @click="hidePicker"></div>
        <div class="w-full h-5/6 bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 flex flex-col">
          <!-- Fixed -->
          <div class="h-auto">
            <div class="text-xl" @click="hidePicker">schließen</div>
            <div class="mt-2 font-faro font-bold text-3xl">{{ selectedList?.Bezeichnung }}</div>
          </div>
          <!-- Scrollable -->
          <div class="flex-grow overflow-y-scroll scrollbar">

          <!-- Lernpfade -->
          <div v-if="filteredLernpfade.length > 0">
            <div class="mt-5 text-xl">Lernpfade</div>
            <div class="w-full md:auto bg-white rounded-xl shadow-new mt-2 border border-hi-lighgrey overflow-hidden">
              <div v-for="(lernpfad, lernpfadIndex) in filteredLernpfade" :key="lernpfad" class="w-full p-3 flex flex-col items-start text-new-black font-arial" :class="{'border-b border-new-yellow' : lernpfadIndex < lernpfad.lernspots.length - 1}">
                <div class="font-bold">{{ lernpfad?.Bezeichnung }}</div>
                <div v-for="(lernspot, lernspotIndex) in lernpfad?.lernspots" :key="lernspot" class="w-full flex flex-col py-5 px-3" :class="{'border-b border-new-yellow' : lernspotIndex < lernpfad.lernspots.length - 1}">
                  <div class="flex flex-row justify-between items-center">
                    <div @click="openLernspot(lernpfad.id, lernspot.id)">{{ lernspot.Bezeichnung }} </div>
                    <xcircleSVG class="flex-shrink-0" @click="removeItemFromList(lernspot.id, 'lernpfade')" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- MC Fragen -->
          <div v-if="mcFragen.length > 0">
            <div class="mt-5 text-xl">MC Fragen</div>
            <div class="w-full md:auto bg-white rounded-xl shadow-new mt-2 border border-hi-lighgrey overflow-hidden">
              <div v-for="(mcFrage, mcFragenIndex) in mcFragen" :key="mcFrage" class="w-full flex flex-col py-5 px-3" :class="{'border-b border-new-yellow' : mcFragenIndex < mcFragen.length - 1}">
                <div class="flex flex-row justify-between items-center">
                  <div>{{ mcFrage?.attributes?.Index }} </div>
                  <xcircleSVG class="flex-shrink-0" @click="removeItemFromList(mcFrage?.id, 'mcFragen')" />
                </div>
              </div>
            </div>
          </div>

          <!-- Pfadaufgaben -->
          <div v-if="pfadaufgaben.length > 0">
            <div class="mt-5 text-xl">Pfadaufgaben</div>
            <div class="w-full md:auto bg-white rounded-xl shadow-new mt-2 border border-hi-lighgrey overflow-hidden">
              <div v-for="(pfadaufgabe, index) in pfadaufgaben" :key="pfadaufgabe" class="w-full flex flex-col py-5 px-3" :class="{'border-b border-new-yellow' : index < pfadaufgaben.length - 1}">
                <div class="flex flex-row justify-between items-center">
                  <div>{{ pfadaufgabe?.attributes?.index }} </div>
                  <xcircleSVG class="flex-shrink-0" @click="removeItemFromList(pfadaufgabe?.id, 'pfadaufgaben')" />
                </div>
              </div>
            </div>
          </div>

          <div v-if="filteredLernpfade.length === 0 && mcFragen.length === 0 && pfadaufgaben.length === 0" class="w-full flex flex-col justify-center items-center mt-10 p-5 text-center">
            <div>Keine Einträge vorhanden</div>
            <div class="text-new-midGrey">Du kannst einen Lernpfad, eine MC-Frage oder eine Pfadaufgabe in dieser Liste speichern.</div>
          </div>

          </div>
        </div>
        <!-- Content -->


      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import queryListLernspots from '../strapi/queryListLernspots.vue';
import queryListMc from '../strapi/queryListMc.vue';
import queryListPfadaufgaben from '../strapi/queryListPfadaufgaben.vue';

// SVG
import xcircleSVG from '../../../assets/svg/xcircle.vue';

export default {
  name: 'listEditDashboard',
  mixins: [slideInOutBottom, iosVibrations],
  components: {
    queryListLernspots,
    xcircleSVG,
    queryListMc,
    queryListPfadaufgaben,
  },

  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    me() {
      return this.$store.state.profile.me;
    },
    userDataId() {
      return this.$store.state.profile.me?.userData?.data?.id;
    },
    selectedList() {
      return this.$store.state.profile.selectedList;
    },
    myLists() {
      return this.me?.userData?.data?.attributes?.myLists;
    },
    updateUserDataList() {
      return this.$store.state.updateUserDataList;
    },
    lernpfade() {
      return this.$store.state.dashboard.listLernspots;
    },
    mcFragen() {
      return this.$store.state.dashboard.listMc;
    },
    pfadaufgaben() {
      return this.$store.state.dashboard.listPfadaufgaben;
    },
    listLernspots() {
      return this.$store.getters.listEntries('lernpfade');
    },
    filteredLernpfade() {
      const filteredLernpfade = [];
      if (this.lernpfade) {
        this.lernpfade.forEach((lernpfad, lernpfadIndex) => {
          filteredLernpfade.push({
            id: lernpfad.id,
            Bezeichnung: lernpfad.attributes.Bezeichnung,
            lernspots: [],
          });
          lernpfad.attributes.lernspots.forEach((lernspot) => {
            if (this.listLernspots.includes(lernspot.id)) filteredLernpfade[lernpfadIndex].lernspots.push(lernspot);
          });
        });
      }
      return filteredLernpfade;
    },
  },

  methods: {

    openLernspot(lernpfadId, lernspotId) {
      console.log(lernpfadId, lernspotId);
      /* this.iosVibrate(0);
      this.$store.commit('setSelectedLernpfadId', lernpfadId);
      this.$store.commit('setSelectedLernspotId', lernspotId);
      this.$store.commit('showOverlay', null);
      this.$router.push({ name: 'lernspot' }); */
    },

    removeItemFromList(id, type) {
      const listIndex = this.myLists.findIndex((list) => list.id === this.selectedList.id);
      const { ids } = this.myLists[listIndex][type];
      ids.splice(ids.indexOf(id), 1);
      this.updateUserDataList({ userDataId: this.userDataId, list: this.myLists });
    },

    hidePicker() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },
  },
};
</script>

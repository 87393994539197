<template>
  <div>
    <!-- Components -->
    <queryLernpfadPreview />
    <queryRanking />
    <listEditDashboard />
    <!-- Components -->

    <!-- Background -->
    <hafn v-if="appName === 'HAFN'" class="hidden md:block absolute bottom-5 right-5 h-95 w-auto opacity-5 z-000"/>

    <!-- Content -->
    <div class="md:pl-24 pt-14 text-new-black font-arial">
      <div class="py-5 md:px-5 md:pr-10" :class="{'pt-safe' : parseInt(sat) !== 0}">

        <!-- Heading -->
        <div class="px-5 mt-6">
          <div class="font-faro font-bold text-3xl">Dashboard</div>
          <span >Hallo {{me?.userData?.data?.attributes?.Vorname}}, willkommen in der {{ appNameLong }}. </span>
        </div>

        <!-- Slider -->
        <div ref="slider" v-if="lernpfadPreview?.length > 0" class="mt-5 px-5 flex flex-row w-auto h-auto md:w-full overflow-auto scrollbar" :class="{'snap-mandatory' : mobile}"
          @mousedown.passive="slideDragStart"
          @mousemove.prevent="slideDrag"
          @mouseup.passive="slideDragEnd">
          <slideItem v-for="(lernpfad, index) in lernpfadPreview" :key="lernpfad"
          :heading="lernpfad?.attributes?.Bezeichnung"
          :lernspotsTotal="lernpfad?.attributes?.lernspots?.length"
          :single="index === 0 && lernpfadPreview.length === 1"
          :lernspotsDone="getFinishedLernspots(lernpfad.id)"
          :previewImage="lernpfad?.attributes?.titelbild?.data?.attributes?.url"
          itemName="Lernspot"
          :class="{
            'flex-shrink-0 ml-5 md:ml-0 mr-3 snap-start' : index === 0,
            'flex-shrink-0 mr-3 snap-start' : index > 0 && index < lernpfadPreview.length - 1,
            'flex-shrink-0 snap-end' : index === lernpfadPreview.length - 1 && lernpfadPreview.length > 1
            }"
          @click="setSelectedLernpfadId(lernpfad.id)"/>
          <div class="flex-shrink-0  h-5 w-5"></div>
        </div>

        <!-- Desktop Wrapper -->
        <div class="md:flex flex-row flex-wrap justify-start items-start">

          <!-- Ranking -->
          <div class=" px-5 mt-5 md:w-1/3 order-last md:min-w-100">
            <div class="font-faro font-bold text-lg mb-2">Ranking</div>
            <div class="bg-white rounded-xl shadow-soft w-full h-auto" @click="openRanking">
              <rankingItem :position="`${myRank}.`" name="Mein Punktestand" :image="myProfileImage" :points="`${totalPoints} Pkt.`" />
            </div>
          </div>

          <!-- Recent trainings -->
          <recentTrainings location="dashboard"/>

          <!-- Lists -->
          <div class="px-5 mt-5 md:w-1/3 md:min-w-100">
            <div class="font-faro font-bold text-lg">Meine Listen</div>
            <div class="bg-white rounded-xl shadow-soft w-full h-auto mt-2 px-5">
              <div v-for="(list, index) in myLists" :key="list">
                <myListItemDashboard
                  :listName="list.Bezeichnung"
                  :border="index < myLists.length - 1"
                   @click="openList(list)" />
              </div>
            </div>
          </div>

        </div>

        <!-- Scrollspace Mobile -->
        <div class="mb-36 md:hidden"></div>

      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import iosVibrations from '../../mixins/iosVibrations.vue';
import detectNotch from '../../mixins/detectNotch.vue';
import mobile from '../../mixins/mobile.vue';
import rankingFunctions from '../../mixins/rankingFunctions.vue';
import learningFunctions from '../../mixins/learningFunctions.vue';
import slideDrag from '../../mixins/slideDrag.vue';

// Components
import slideItem from './components/slideItem.vue';
import rankingItem from '../ranking/components/rankingItem.vue';
import recentTrainings from '../training/components/recentTrainigs.vue';
import myListItemDashboard from './components/myListItemDashboard.vue';
import listEditDashboard from './components/listEditDashboard.vue';

// Strapi
import queryLernpfadPreview from './strapi/queryLernpfadPreview.vue';
import queryRanking from '../ranking/strapi/queryRanking.vue';

// SVG
import hafn from '../../assets/svg/hafn.vue';

export default {
  name: 'dashboard',
  mixins: [iosVibrations, detectNotch, mobile, rankingFunctions, learningFunctions, slideDrag],
  components: {
    slideItem,
    rankingItem,
    hafn,
    queryLernpfadPreview,
    queryRanking,
    myListItemDashboard,
    recentTrainings,
    listEditDashboard,
  },

  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      appNameLong: process.env.VUE_APP_NAMELONG?.replace('-', ' '),
    };
  },

  computed: {
    currentCourseId() {
      return this.$store.state.settings.currentCourseID;
    },
    me() {
      return this.$store.state.profile.me;
    },
    myLists() {
      return this.me?.userData?.data?.attributes?.myLists;
    },
    lernpfadProgress() {
      return this.me.userData.data.attributes.lernpfadProgress;
    },
    lernpfadPreview() {
      const lernpfadePreview = this?.$store?.state?.dashboard?.lernpfadPreview;
      const lastLernpfade = this?.me?.userData?.data?.attributes?.lastLernpfade;
      const filteredLernpfade = [];
      if (lernpfadePreview && lastLernpfade) {
        console.log(lernpfadePreview);
        console.log(lastLernpfade);
        lernpfadePreview.forEach((lernpfad, index) => {
          const lernpfadIndex = lastLernpfade.findIndex((l) => l.lernpfadId === lernpfad.id);
          console.log(lernpfadIndex);
          if (index && lernpfadIndex) {
            lernpfadePreview[index].updated = lastLernpfade[lernpfadIndex]?.updated;
            lernpfadePreview[index].courseId = lastLernpfade[lernpfadIndex]?.courseId;
          }
          if (lernpfad?.courseId === this?.currentCourseId) filteredLernpfade.push(lernpfad);
        });
      }
      const sortedLastLernpfade = filteredLernpfade?.sort((a, b) => Date.parse(b?.updated) - Date.parse(a?.updated));
      return sortedLastLernpfade;
    },
    myRecentTrainings() {
      // TODO: add battle & pfadaufgaben, filter nach Kurs
      const trainings = this.$store.state?.profile?.me?.userData?.data?.attributes?.recentTrainings;
      return trainings.slice(0, 3);
    },
    selectedLernpfadId() {
      return this.$store.state.learning.selectedLernpfadId;
    },
  },

  watch: {
    currentCourseId() {
      this.$store.commit('resetLernpfadIds');
      this.queryLernpfadPreview();
    },
  },

  mounted() {
    this.queryLernpfadPreview();
  },

  methods: {

    openList(list) {
      this.iosVibrate(0);
      this.$store.commit('setSelectedList', list);
      this.$store.commit('showOverlay', 'listEditDashboard');
    },

    openRanking() {
      this.$router.push({ name: 'ranking' });
    },

    queryLernpfadPreview() {
      const lernpfade = this?.me?.userData?.data?.attributes?.lastLernpfade;
      if (lernpfade) {
        const lernpfadeToQuery = [];
        lernpfade.forEach((lernpfad) => {
          if (lernpfad.courseId === this.$store.state.settings.currentCourseID) {
            lernpfadeToQuery.push(lernpfad.lernpfadId);
          }
          this.$store.commit('setLernpfadIds', lernpfadeToQuery);
        });
      }
    },

    getFinishedLernspots(id) {
      const lernpfadIndex = this.lernpfadProgress?.lernpfade.findIndex((lernpfad) => lernpfad.id === id);
      if (lernpfadIndex === -1) {
        return 0;
      }
      let finishedSpots = 0;
      this.lernpfadProgress.lernpfade[lernpfadIndex].lernspots.forEach((lernspot) => {
        if (lernspot.finished) {
          finishedSpots += 1;
        }
      });
      return finishedSpots;
    },

    setSelectedLernpfadId(id) {
      this.iosVibrate(0);
      this.saveLastLernpfad(id);
      let reset = false;
      if (this.selectedLernpfadId !== id && this.mobile) reset = true;
      this.$store.commit('setSelectedLernpfadId', id);
      if (this.mobile) {
        if (reset) this.$store.commit('resetSelectedLernpfad');
        this.$router.push({ name: 'lernpfadMobile' });
      } else {
        this.$router.push({ name: 'learning' });
      }
    },
  },

};
</script>
